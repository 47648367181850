import React, { Component } from 'react';
import Layout from '../components/Layout';

class error extends Component {

    render() {


        return (
            <Layout newsletter="true" about="false">
                <div className="row mt-5 py-5 justify-content-center bg-title-box">
                    <div className="col-md-12 col-12 my-5">
                        <h1 className="text-center t my-5">اوه... اوه! حتما یه اشتباهی شده! خطای ۴۰۴</h1>
                        <p className="text-center t my-5">نشانی صفحه مورد نظرتان را مجدد بررسی کنید یا از منوی اصلی سایت، به بخش مورد نظر خود مراجعه کنید.</p>
                    </div>
                </div>
                <div className="row my-md-5 py-md-5"></div>      
            </Layout>
        )
    }
}

export default error;